  <template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs pt-3" style="border: 0">
          <li class="active"
            ><a href="#" class="btn btn-sm btn-head text-white"
              >Report ESPR</a
            ></li
          >
        </ul>
        <div class="card new-card">
          <div class="card-body">
            <!-- <div class="row">

            </div> -->
            <Worksheet :show-action="true"></Worksheet>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

  <script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import Worksheet from '@components/ReportWorksheet/TableWorksheet.vue'

// import { mapActions } from 'vuex'

export default {
  page: {
    title: 'Report ESPR',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Worksheet,
    Layout,
    // PageHeader,
  },
  data() {
    return {
      title: 'ESPR',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'ESPR',
          href: '/report-espr',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    }
  },
}
</script>
