<template>
  <div>
    <div class="row mb-3">
      
      <div class="col-md-2">
        <label>Range Type</label>
        <select
          v-model="rangeData"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="customData" selected>Custom</option>
          <option value="monthlyData">Monthly</option>
          <!-- <option value="yearlyData">Yearly</option> -->
        </select>
      </div>
      <div
        v-if="rangeData === 'customData'"
        class="col-md-2 justify-content-end"
      >
        <label>Start Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="startDateData"
          :format="momentFormatStartDateData"
          type="date"
          lang="en"
          :name="'startDate'"
          :placeholder="'Start Date'"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData === 'customData'"
        class="col-md-2 justify-content-end"
      >
        <label>End Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="endDateData"
          :format="momentFormatEndDateData"
          type="date"
          lang="en"
          :name="'endDate'"
          :placeholder="'End Date'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData === 'monthlyData'"
        class="col-md-2 justify-content-end"
      >
        <label>Month</label>
        <select
          v-model="monthData"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
      <div v-if="rangeData === 'monthlyData' || rangeData === 'yearlyData'" class="col-md-2 justify-content-end">
        <label>Year</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="yearData"
          type="year"
          :format="formatYearData"
          :name="'yearData'"
          :placeholder="'Year'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div class="col-md-2">
        <label>Jobfile Type</label>
        <select
          v-model="jobfileTypeData"
          class="form-control"
          name="jobfileType"
          data-live-search="true"
          placeholder="Jobfile Type"
        >
          <option value="All" selected>All</option>
          <option value="1">Export</option>
          <option value="2">Import</option>
          <option value="3">Domestic</option>
        </select>
      </div>
      <div class="col-md-2">
        <label>Sales</label>
        <multiselect
          id="marketing"
          v-model="marketingData"
          :options="marketingList" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true" 
          placeholder="Choose a Marketing"
          :custom-label="(opt) => opt.name_user"
          track-by="id_user" 
          :preselect-first="true"
        >
           </multiselect>
        <!-- <v-select v-model="marketing" v-can="'CRM_ADMIN_ENABLE'" :options="listMarketingByBranchData1" label="name_user" placeholder="Choose a Marketing">
        </v-select> -->
      </div>
      <div class="col-md-2">
        <label>Status</label>
        <select
          v-model="jobfileTypeTransaction"
          class="form-control"
          name="typeTransaction"
          data-live-search="true"
          placeholder="Jobfile Type"
        >
          <option value="All" selected>All</option>
          <option value="1">Open</option>
          <option value="2">In Progress</option>
          <option value="3">Closed</option>
        </select>
      </div>
      

      
      
    </div>

    <div class="row">
      <div class="col-md-8" style="align-content: center; display: flex"> </div>
        <div class="col-md-4" style="display: flex; justify-content: end">
          <!-- <label class="mt-3">Search</label> -->
            <input
              v-model="filterText2"
              type="text"
              class="form-control form-control-sm ml-2 mt-2"
              placeholder="Search . . ."
              @keyup="doFilter2"
            />
        <div>
          <b-dropdown
            id="dropdown-1"
            right
            variant="success"
            class="m-md-2"
            no-caret
          >
            <template v-slot:button-content>
              Preview
              <i class="fa fa-caret-down ml-2"></i>
            </template>
            <b-dropdown-item @click="doFilterMultipleKeywords"
              ><i class="fa fa-edit mr-2"></i>Filter Data</b-dropdown-item
            >
            <b-dropdown-item @click="exportData"
              ><i class="fa fa-edit mr-2"></i>Export Excel</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <!-- <h4 class="header-title">List Quotation</h4> -->
    <vue-element-loading
      :active="isLoadingTable"
      spinner="bar-fade-scale"
      color="#FF6700"
    />

    <!-- Table -->
    <div class="table-responsive">
      <vuetable
        ref="vuetable"
        :key="index"
        :per-page="perPage"
        :fields="fields"
        :api-url="apiUrl"
        :api-mode="true"
        :http-options="httpOptions"
        :show-sort-icons="true"
        :append-params="moreParams"
        pagination-path
        class="table table-centered table-striped"
        data-path="data"
        @vuetable:loading="loadingTable"
        @vuetable:load-success="loadTableSuccess"
        @vuetable:load-error="loadTableFailed"
        @vuetable:pagination-data="onPaginationData"
      >
      <div slot="job_no" slot-scope="props">
        <a class="waves-effect waves-dark"
              :href="urlCi + '/admin/jobfile/data?jobfile=' + props.rowData.id_job"
              aria-expanded="false"
            >
        {{ props.rowData.job_no }}

        </a>
      </div>
      </vuetable>
    </div>
    <!-- end table -->

    <!-- pagination -->
    <div class="row">
      <div class="col-md-5">
        <div class="row no-gutters">
          <div class="col-lg-3">
            <div class="form-group float-lg-left">
              <select
                v-model="perPage"
                class="form-control form-control-sm"
                @change="onChangeTableLength"
              >
                <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                  {{ opt }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
            <vuetable-pagination-info
              ref="paginationInfo"
              class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              "
            >
            </vuetable-pagination-info>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <vuetable-pagination
          ref="pagination"
          :css="css.pagination"
          class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          "
          @vuetable-pagination:change-page="onChangePage"
        >
        </vuetable-pagination>
      </div>
    </div>
    <!-- end pagination -->
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Multiselect from 'vue-multiselect'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'

var url = '/report-espr'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    url = '/report-espr'
  }
}
console.log(url, 'url')

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker,
    Multiselect
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    options: {
      type: Object,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      moreParams: {},
      filterText: '',
      filterText2: '',
      deleteData: {},
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + url,
      urlPdf: '#',
      urlCi: process.env.VUE_APP_CI_URL,
      lengthMenu: [10, 25, 50, 100],
      perPage: 10,
      fields: [
        {
          name: 'no',
          title: 'No.',
        },

        {
          name: 'job_no',
          title: 'Job No'
        },
        {
          name: 'date',
          title: 'Create Date',
        },
        {
          name: 'month',
          title: 'Month',
        },
        {
          name: 'status',
          title: 'Status',
        },
        {
          name: 'marketing',
          title: 'Marketing',
        },
        {
          name: 'pic_operation',
          title: 'PIC Operation',
        },
        {
          name: 'shipper',
          title: 'Shipper',
        },
        {
          name: 'consignee',
          title: 'Consignee',
        },
        {
          name: 'customer',
          title: 'Customer',
        },
        {
          name: 'transport_mode',
          title: 'Transport Mode',
        },
        {
          name: 'load',
          title: 'Load',
        },
        {
          name: 'traffic',
          title: 'Traffic',
        },
        {
          name: 'incoterms',
          title: 'INCO Term',
        },
        {
          name: 'add_term',
          title: 'Add Term',
        },
        {
          name: 'pol',
          title: 'Port of Loading',
        },
        {
          name: 'pod',
          title: 'Port of Discharge',
        },
        {
          name: 'etd',
          title: 'ETD',
        },
        {
          name: 'eta',
          title: 'ETA',
        },
        {
          name: 'type_value',
          title: 'Type Value',
        },
        
        {
          name: 'ment',
          title: 'Measurement',
        },
        {
          name: 'gweight',
          title: 'Weight',
        },
        {
          name: 'volume',
          title: 'Volume',
        },
        
        {
          name: 'package',
          title: 'Package',
        },
        {
          name: 'type_package',
          title: 'Type of Package',
        },
        {
          name: 'container_number',
          title: 'Container(s) No.',
        },
        {
          name: 'commodity',
          title: 'Commodity',
        },
        {
          name: 'mbl',
          title: 'Master BL/AWB',
        },
        {
          name: 'hbl',
          title: 'House BL/AWB',
        },
        {
          name: 'carrier',
          title: 'Carrier',
        },
        {
          name: 'vessel',
          title: 'Vessel/Flight',
        },
        
        {
          name: 'debit_note',
          title: 'Debit Note',
        },
        {
          name: 'revenue',
          title: 'Revenue',
        },
        {
          name: 'expense',
          title: 'Expense',
        },
        {
          name: 'reimburse',
          title: 'Reimburse',
        },
        {
          name: 'vat_inv',
          title: 'Vat_INV',
        },
        {
          name: 'total_billing',
          title: 'Total_Billing',
        },
        {
          name: 'total_cash_out',
          title: 'Total_Cash Out',
        },
        {
          name: 'credit_note',
          title: 'Credit Note',
        },
        
      ],
      css: {
        pagination: {
          activeClass: 'btn-success text-white',
          pageClass: 'btn btn-sm',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      momentFormatStartDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
        },
      },
      year: null,
      createdBy: null,
      statusData: 'All',
      startDateData: null,
      endDateData: null,
      branchData: [],
      list: [],
      rangeData: 'customData',
      marketingData:[],
      marketingPermission:[],
      topCustomerData: '15',
      compareData: false,
      isManualTopCustData: false,
      isAutoTopCustData: true,
      monthData: '01',
      startDateCalculated:'',
      endDateCalculated:'',

      yearData: null,
      formatYearData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData).format('YYYY') : ''
        },
      },
      isLoading: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      jobfileTypeData: 'All',
      jobfileTypeTransaction: 'All',
      customerData: [],
      marketingList:[
            {
        id_user: 'All',
        name_user: 'All'
      }
      ]
    }
  },
  computed: {
    itemsWithSno() {
      return this.desserts.map((d, index) => ({ ...d, sno: index + 1 }))
    },

    ...mapState('user', ['listMarketingByBranchData1', 'listUser', 'listMarketingByPermissions']),
  },
  mounted() {
    this.getListUser()
    this.getMarketingByPermission()
  },
  methods: {
    ...authMethods,
    errorAlertValidation: function (message = 'Terjadi Kesalahan') {
      this.$swal({
        title: message,
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },
    loginByToken() {
      console.log('login by token')
    },

    ...mapActions('user', [
      'getListUser',
      'getListMarketingByBranchData1',
      'getListMarketingByBranchData2',
      'getListMarketingByPermissions',
    ]),

    getMarketingByBranchData(permission) {
      try {
        // let request = []
        // let strBranch = 'id_branch[]=' + localStorage.getItem('default_branch')
        // this.branchData.forEach((data, idx) => {
        //   if (idx === 0) {
        //     strBranch += 'id_branch[]=' + data.id_branch
        //     request.push(strBranch, permission)
        //   } else {
        //     strBranch += '&id_branch[]=' + data.id_branch
        //     request.push(strBranch, permission)
        //   }
        // })
        let permissionName = '&permissionName[] =' + permission
        console.log(permissionName,'permissionName');
        this.getListMarketingByPermissions(permissionName)
          .then((res) => {
            console.log(res, 'test return permission')
            // this.user_id = res.result[1].id
            for (let i = 0; i < res.result.length; i++) {
              const element = res.result[i];
              this.marketingPermission.push(element.id_user)
              this.marketingList.push({
                id_user : element.id_user,
                name_user: element.name_user
              })
            }
            console.log(this.marketingPermission, 'marketing permission')
          })
          // .catch((err) => {
          //   console.log(err)
          // })
      } catch (error) {
        console.log(error)
      }
    },

    getMarketingByPermission(){
      for (
        let i = 0;
        i < localStorage.getItem('userPermissions').length;
        i++
      ) {
        let enable = 'REPORT_ESPR_ENABLE'
        let view = 'REPORT_ESPR_VIEW'
        let user = 'REPORT_ESPR_USERS'
        let parseUserPermission = localStorage.getItem('userPermissions')
        if ( 
          parseUserPermission.includes(enable) && parseUserPermission.includes(view) && parseUserPermission.includes(user)
        ) {
            this.getMarketingByBranchData(user);
            break
        }
            else if(parseUserPermission.includes(enable) && parseUserPermission.includes(view)){
              this.getMarketingByBranchData(view);
              break
            }
          else if(parseUserPermission.includes(user)){
              this.getMarketingByBranchData(user);
              break
            }
          else if(parseUserPermission.includes(enable)){
            this.getMarketingByBranchData(enable);
            break
          }
      }
    },

    doFilter() {
      this.onFilterSet(this.filterText)
    },
    doFilter2() {
      this.onFilterSet2(this.filterText2)
      // console.log(this.branchData1, 'THIS BRANCH DATA 1');
    },
    doFilterMultipleKeywords() {
      this.onFilterSetMultipleKeywords('filter')
    },
    exportData() {
      this.onFilterSetMultipleKeywords('export')
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterSet2(filterText2) {
      console.log(filterText2)
      let strMarketing = []
      if(this.marketingData.length === 0){
        this.marketingData = [
          {
                id_user:'All',
                name_user: 'All'
              }
          ]
      }
      this.marketingData.forEach((data, idx) => {
        // if(data.id == 0){
        //   data.id = ''
        // }
        if(data.id === 'All'){
          for (let i = 0; i < this.marketingPermission.length; i++) {
            const element = this.marketingPermission[i];
            strMarketing.push(element)
          }
        }else{
          strMarketing.push(data.id_user)

        }
      })
      setTimeout(() => {
        this.moreParams = {
          branch: localStorage.getItem('default_branch'),
          company: localStorage.getItem('id_company'),
          jobfileType: this.jobfileTypeData,
          startDate: this.startDateCalculated,
          endDate: this.endDateCalculated,
          TypeTransaction: this.jobfileTypeTransaction,
          sales:strMarketing,
          perPage: 10,
          filter: true,
          search: filterText2,
        }

        console.log(this.moreParams, 'THIS DATA SEARCH')
        this.$nextTick(() => this.$refs.vuetable.refresh())
      }, 0)
    },
    onFilterSetMultipleKeywords(filterText) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      if (this.rangeData === 'customData') {
        tempStartDateData = new Date(
          new Date(this.startDateData.toString()).getFullYear(),
          new Date(this.startDateData.toString()).getMonth(),
          new Date(this.startDateData.toString()).getDate() + 1
        )
          .toISOString()
          .split('T')[0]

        tempEndDateData = new Date(
          new Date(this.endDateData.toString()).getFullYear(),
          new Date(this.endDateData.toString()).getMonth(),
          new Date(this.endDateData.toString()).getDate() + 1
        )
          .toISOString()
          .split('T')[0]
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            parseInt(this.monthData.toString()) - 1,
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            parseInt(this.monthData.toString()),
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            0,
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            12
          )
            .toISOString()
            .split('T')[0]
        }
      }

      let strMarketing = []
      if(this.marketingData.length === 0){
        this.marketingData = [
          {
                id_user:'All',
                name_user: 'All'
              }
          ]
      }
      this.marketingData.forEach((data, idx) => {
        // if(data.id == 0){
        //   data.id = ''
        // }
        if(data.id_user === 'All'){
          for (let i = 0; i < this.marketingPermission.length; i++) {
            const element = this.marketingPermission[i];
            strMarketing.push(element)
          }
        }else{
          strMarketing.push(data.id_user)

        }
      })
      console.log(this.marketingData, 'marketing data')

      // let strCustomer = []
      // this.customerData.forEach((data, idx) => {
      //   strCustomer.push(data.id)
      // })

      if (this.jobfileType === '') {
        this.errorAlertValidation('Jobfile Type is required!')
        return false;
      } else if (this.statusData === '') {
        this.errorAlertValidation('Status is required!')
        return false;
      } else if (this.rangeData === '') {
        this.errorAlertValidation('Range is required!')
        return false;
      } else if (this.rangeData !== '') {
        if (this.rangeData === 'customData') {
          if (this.startDateData === '' || this.startDateData == null) {
            this.errorAlertValidation('Start Date is required!')
            return false;
          } else if (this.endDateData2 === '' || this.endDateData == null) {
            this.errorAlertValidation('End Date is required!')
            return false;
          } else {
            if(this.diffDays(new Date(this.startDateData), new Date(this.endDateData)) > 32) {
              this.errorAlertValidation('The data displayed / exported must not be more than 30 days')
              return false;
            } else {
              this.$nextTick(() => this.$refs.vuetable.refresh())
            }
          }
        } else if (this.rangeData === 'monthlyData') {
          if (this.monthData === '' || this.monthData == null) {
            this.errorAlertValidation('Month is required!')
            return false;
          } else if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
            return false;
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'yearlyData') {
          if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
            return false;
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        }
      }
      this.startDateCalculated = tempStartDateData
      this.endDateCalculated = tempEndDateData
      let sales = []
        for (let i = 0; i < strMarketing.length; i++) {
          const element = strMarketing[i];
          sales.push(
            'sales[]=' + element
          )
          
        }
       let test = sales.join("&");
      if (filterText === 'filter') {
        this.moreParams = {
          branch: localStorage.getItem('default_branch'),
          company: localStorage.getItem('id_company'),
          jobfileType: this.jobfileTypeData,
          startDate: tempStartDateData,
          endDate: tempEndDateData,
          TypeTransaction: this.jobfileTypeTransaction,
          sales:strMarketing,
          perPage: 10,
          filter: true,
        }
        console.log(this.moreParams, 'THIS PARAMSSS')
        // console.log(filterText)
        this.$nextTick(() => this.$refs.vuetable.refresh())
      } else {
        var idCompany = localStorage.getItem('id_company')
        var branch = localStorage.getItem('default_branch')

        window.location.href = `${this.$store.state.rootApi}/export-excel-espr?jobfileType=${this.jobfileTypeData}&TypeTransaction=${this.jobfileTypeTransaction}&startDate=${tempStartDateData}&endDate=${tempEndDateData}&branch=${branch}&company=${idCompany}&${test}&filter=true`
        console.log(tempStartDateData)
      }
    },
    
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },

    formatDate(type) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      if (tempStartDateData === null || tempEndDateData === null) {
        return ''
      }
      return (
        this.monthNames[tempStartDateData.getMonth()] +
        ' ' +
        tempStartDateData.getFullYear() +
        ' - ' +
        this.monthNames[tempEndDateData.getMonth()] +
        ' ' +
        tempEndDateData.getFullYear()
      )
    },
    fecthData() {
      console.log('OK')
    },
    diffDays(date, otherDate) {
      return (Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1)
    }
  },
}
</script>
